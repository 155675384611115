import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const Step = props => {
  const { className, isStepFilled, text } = props;

  const stepClasses = classNames(css.step, className, {
    [css.stepFilled]: isStepFilled,
  });
  const stepTextClasses = classNames(css.stepText, {
    [css.stepTextFilled]: isStepFilled,
  });
  return (
    <div className={stepClasses}>
      <span className={stepTextClasses}>{text}</span>
    </div>
  );
};

const SingleStep = props => {
  const { intl, label, value } = props;

  // Step label
  const stepLabel =
    label === 'jobQuality'
      ? 'Job quality'
      : label === 'materialQuality'
      ? 'Material quality'
      : label;

  // Declare which step is filled
  const isFirstStepFilled = value >= 0;
  const isSecondStepFilled = value >= 1;
  const isThirdStepFilled = value >= 2;

  // Messages
  const lowPriorityText = intl.formatMessage({ id: 'ListingPage.lowPriority' });
  const neutralPriorityText = intl.formatMessage({ id: 'ListingPage.neutralPriority' });
  const highPriorityText = intl.formatMessage({ id: 'ListingPage.highPriority' });

  const stepLineClasses = classNames(css.stepLine, {
    [css.stepLineFilled]: isSecondStepFilled,
    [css.stepLineFilledFull]: isThirdStepFilled,
  });
  return (
    <div className={css.singleStep}>
      <label className={css.stepLabel}>{stepLabel}</label>
      <div className={stepLineClasses}>
        <Step className={css.firstStep} isStepFilled={isFirstStepFilled} text={lowPriorityText} />
        <Step
          className={css.secondStep}
          isStepFilled={isSecondStepFilled}
          text={neutralPriorityText}
        />
        <Step className={css.thirdStep} isStepFilled={isThirdStepFilled} text={highPriorityText} />
      </div>
    </div>
  );
};

const SectionPriorities = props => {
  const { intl, publicData } = props;
  const servicePriorities = publicData?.servicePriorities;

  const hasServicePriorities =
    servicePriorities && Object.values(servicePriorities).filter(s => s !== null)?.length > 0;
  if (!hasServicePriorities) {
    return null;
  }
  return (
    <div className={css.sectionPriorities}>
      <h2 className={css.prioritiesTitle}>
        <FormattedMessage id="ListingPage.prioritiesTitle" />
      </h2>
      <div className={css.steps}>
        {Object.keys(servicePriorities).map((v, index) => {
          if (servicePriorities[v] === null) {
            return null;
          }
          return <SingleStep key={index} intl={intl} label={v} value={servicePriorities[v]} />;
        })}
      </div>
    </div>
  );
};

export default SectionPriorities;
