import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  isUserPro,
  isUserCompany,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  Modal,
  InviteSingleProfessionalModal,
  InlineTextButton,
  ShareModal,
  IconShareProfile as IconShareListing,
  SubscriptionModal,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { inviteProfessional } from '../ProfessionalPage/ProfessionalPage.duck';

import {
  sendEnquiry,
  sendMessage,
  fetchTransactionLineItems,
  setInitialValues,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionInformations from './SectionInformations';
import SectionWorkOrderLink from './SectionWorkOrderLink';
import SectionPurchaseOrder from './SectionPurchaseOrder';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionDocumentsMaybe from './SectionDocumentsMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionPriorities from './SectionPriorities';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = price instanceof Money ? formatMoney(intl, price) : null;
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isInviteProfessionalModalOpen: false,
      isShareModalOpen: false,
      isSubscriptionModalOpen: false,
    };

    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitMessage = this.onSubmitMessage.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    const locationState = location?.state;
    const isInviteProfessionalModalOpen = locationState?.isInviteProfessionalModalOpen;

    if (isInviteProfessionalModalOpen) {
      this.setState({ isInviteProfessionalModalOpen });
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { currentUser, location, history, currentUserQuotes } = this.props;
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      const isCurrentUserPro = isUserPro(currentUser);
      const showSubscriptionModal = !isCurrentUserPro && currentUserQuotes >= 2;
      if (showSubscriptionModal) {
        this.setState({ isSubscriptionModalOpen: true });
      } else {
        const { budget, document } = values;
        const { history, params, onSendEnquiry, currentUserProfessionalListingId } = this.props;
        const routes = routeConfiguration();
        const listingId = new UUID(params.id);
        const professionalListingId = currentUserProfessionalListingId?.uuid;

        onSendEnquiry({ listingId, professionalListingId, budget, document })
          .then(txId => {
            this.setState({ enquiryModalOpen: false });

            // Redirect to OrderDetailsPage
            history.push(
              createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
            );
          })
          .catch(() => {
            // Ignore, error handling in duck file
          });
      }
    }
  }

  onSubmitMessage(values) {
    const { message } = values;
    const { history, params, onSendMessage } = this.props;
    const listingId = new UUID(params.id);
    const routes = routeConfiguration();

    onSendMessage(listingId, message)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      history,
      location,
      scrollingDisabled,
      showListingError,
      filterConfig,
      professionalRefs,
      queryProfessionalsInProgress,
      queryProfessionalsError,
      onInviteProfessional,
      inviteProfessionalInProgress,
      inviteProfessionalError,
      sendEnquiryInProgress,
      sendEnquiryError,
      isCurrentUserProfessional,
      isCurrentUserClient,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    const professionalProfile = currentUser?.attributes?.profile?.publicData?.profile;
    const isProfessionalProfileSetup =
      isCurrentUserProfessional && professionalProfile && professionalProfile.type === 'edit';

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    // Query professionals modal params
    const queriedProfessionals = professionalRefs.map(l => getListing(l));

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const isAuthorPro = isUserPro(ensuredAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice } = priceData(price, intl);
    const unknownPrice = publicData?.unknownPrice;

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const authorUserType = ensuredAuthor.attributes.profile.publicData?.userType;
    const professionalProfileParams = ensuredAuthor.attributes.profile.publicData?.profile;
    const hostLink =
      authorUserType === config.userTypeProfessional ? (
        professionalProfileParams && (
          <NamedLink
            className={css.authorNameLink}
            name="ProfessionalPage"
            params={{ ...professionalProfileParams }}
          >
            {authorDisplayName}
          </NamedLink>
        )
      ) : ensuredAuthor?.id?.uuid ? (
        <NamedLink
          className={css.authorNameLink}
          name="ProfilePage"
          params={{ id: ensuredAuthor?.id?.uuid }}
        >
          {authorDisplayName}
        </NamedLink>
      ) : null;

    const skillsOptions = findOptionsForSelectFilter('project-skills', filterConfig);
    const timeframeOptions = findOptionsForSelectFilter('project-timeframe', filterConfig);
    const occupationOptions = findOptionsForSelectFilter('project-occupation', filterConfig);

    const listingDocuments = publicData?.documents;
    const showProfessionalsModal =
      isOwnListing && !queryProfessionalsError && this.state.isInviteProfessionalModalOpen;
    const isVariant = isPendingApprovalVariant || isDraftVariant;

    const hasListingState = !!currentListing.attributes.state;
    const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;
    const showContactButton =
      !isOwnListing && !isCurrentUserClient && isProfessionalProfileSetup && !isClosed;

    if (publicData?.type === config.listingTypeProfessional) {
      return <NamedRedirect name="NotFoundPage" />;
    }

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.contentContainer}>
                <div className={css.contentWrapper}>
                  <div className={css.mainContent}>
                    <div className={css.iconShareContainer}>
                      <div
                        className={css.iconShareButton}
                        onClick={() => this.setState({ isShareModalOpen: true })}
                      >
                        <IconShareListing className={css.iconShare} />
                      </div>
                    </div>
                    <SectionHeading
                      richTitle={richTitle}
                      hostLink={hostLink}
                      isOwnListing={isOwnListing}
                      onOpenProfessionalsModal={() =>
                        this.setState({ isInviteProfessionalModalOpen: true })
                      }
                      showContactButton={showContactButton}
                      onContactUser={this.onContactUser}
                      formattedPrice={formattedPrice}
                      showRecommendedProfiles={!isVariant && isOwnListing}
                      unknownPrice={unknownPrice}
                      authorDisplayName={authorDisplayName}
                      bookingDates={publicData?.dates}
                      isAuthorCompany={isUserCompany(ensuredAuthor)}
                    />
                    <SectionDescriptionMaybe description={description} />
                    <SectionInformations
                      author={ensuredAuthor}
                      isAuthorPro={isAuthorPro}
                      publicData={publicData}
                      timeframeOptions={timeframeOptions}
                      occupationOptions={occupationOptions}
                    />
                    <SectionWorkOrderLink publicData={publicData} />
                    <SectionPurchaseOrder publicData={publicData} />
                    <SectionFeaturesMaybe options={skillsOptions} publicData={publicData} />
                    <SectionPriorities intl={intl} publicData={publicData} />
                    <SectionDocumentsMaybe documents={listingDocuments} />
                  </div>
                  <div className={css.bookingPanel}>
                    <BookingPanel
                      className={css.bookingPanelWrapper}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      onSubmit={this.onSubmitEnquiry}
                      title={bookingTitle}
                      onManageDisableScrolling={onManageDisableScrolling}
                      sendEnquiryInProgress={sendEnquiryInProgress}
                      sendEnquiryError={sendEnquiryError}
                      quoteFormId="QuoteForm"
                      onContactUser={this.onContactUser}
                      unknownPrice={unknownPrice}
                      isCurrentUserClient={isCurrentUserClient}
                      isCurrentUserProfessional={isCurrentUserProfessional}
                      isProfessionalProfileSetup={isProfessionalProfileSetup}
                      showContactButton={showContactButton}
                      currentUser={currentUser}
                      currentAuthor={currentAuthor}
                    />
                    {showContactButton ? (
                      <div className={css.contactButtonContainer}>
                        <InlineTextButton className={css.contact} onClick={this.onContactUser}>
                          <FormattedMessage id="ListingPage.contactUser" />
                        </InlineTextButton>
                      </div>
                    ) : null}
                  </div>
                </div>
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                />
              </div>
              <Modal
                id="ListingPage.enquiry"
                contentClassName={css.enquiryModalContent}
                isOpen={currentUser && this.state.enquiryModalOpen}
                onClose={() => this.setState({ enquiryModalOpen: false })}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <EnquiryForm
                  className={css.enquiryForm}
                  submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                  listingTitle={title}
                  authorDisplayName={authorDisplayName}
                  sendEnquiryError={sendEnquiryError}
                  onSubmit={this.onSubmitMessage}
                  inProgress={sendEnquiryInProgress}
                />
              </Modal>
              {/* As the ListingPage component is itself already large
                  we will define the modal functions directly bellow */}
              <InviteSingleProfessionalModal
                id="InviteSingleProfessionalModal"
                isOpen={showProfessionalsModal}
                onCloseModal={() => this.setState({ isInviteProfessionalModalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                onSubmit={profileId =>
                  onInviteProfessional({
                    profileId,
                    projectId: listingId.uuid,
                    projectTitle: title,
                  })
                }
                onRedirectToSearchPage={() =>
                  history.push(pathByRouteName('SearchProfessionalsPage', routeConfiguration()))
                }
                inviteInProgress={inviteProfessionalInProgress}
                inviteError={inviteProfessionalError}
                queryProfessionalsInProgress={queryProfessionalsInProgress}
                professionals={queriedProfessionals}
                projectId={listingId.uuid}
              />
              <ShareModal
                id="ListingPage.shareModal"
                isOpen={this.state.isShareModalOpen}
                onCloseModal={() => this.setState({ isShareModalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <SubscriptionModal
                id="SusbcriptionModal"
                isOpen={this.state.isSubscriptionModalOpen}
                onCloseModal={() => this.setState({ isSubscriptionModalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                subscriptionMessageKey="ListingPage.subscriptionMessage"
                history={history}
                location={location}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    professionalRefs,
    queryProfessionalsInProgress,
    queryProfessionalsError,
  } = state.ListingPage;

  const {
    currentUser,
    currentUserProfessionalListingId,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserQuotes,
  } = state.user;
  const { inviteProfessionalInProgress, inviteProfessionalError } = state.ProfessionalPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    professionalRefs,
    queryProfessionalsInProgress,
    queryProfessionalsError,
    inviteProfessionalInProgress,
    inviteProfessionalError,
    currentUserProfessionalListingId,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserQuotes,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: params => dispatch(sendEnquiry(params)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onInviteProfessional: params => dispatch(inviteProfessional(params)),
  onSendMessage: (listingId, message) => dispatch(sendMessage(listingId, message)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
