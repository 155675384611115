import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import moment from 'moment';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    richTitle,
    hostLink,
    showRecommendedProfiles,
    onOpenProfessionalsModal,
    formattedPrice,
    unknownPrice,
    authorDisplayName,
    onContactUser,
    showContactButton,
    bookingDates,
    isAuthorCompany,
  } = props;

  const start = bookingDates?.start;
  const end = bookingDates?.end;
  const startDate = start ? moment(start).format('MMM DD') : null;
  const endDate = end ? moment(end).format('MMM DD') : null;
  const endYear = end ? moment(end).format('YYYY') : null;

  const showBookingDates = startDate && endDate && endYear;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.listingInfo}>
          <div className={css.author}>
            {isAuthorCompany ? (
              <FormattedMessage
                id="ListingPage.companyHostedBy"
                values={{ name: hostLink ? hostLink : authorDisplayName }}
              />
            ) : (
              <FormattedMessage
                id="ListingPage.hostedBy"
                values={{ name: hostLink ? hostLink : authorDisplayName }}
              />
            )}
            {showContactButton ? (
              <>
                <span className={css.separator}>•</span>
                <InlineTextButton className={css.viewProfessionalsButton} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.contact" />
                </InlineTextButton>
              </>
            ) : null}
            <div className={css.priceContainer}>
              <span className={css.separator}>•</span>
              <span className={css.priceValue}>
                <FormattedMessage
                  id="ListingPage.budget"
                  values={{
                    budgetPrice: (
                      <span className={css.budgetPrice}>
                        {!!unknownPrice ? 'Budget unknown' : formattedPrice}
                      </span>
                    ),
                  }}
                />
              </span>
            </div>

            {showBookingDates ? (
              <>
                <span className={css.separator}>•</span>
                <span className={css.bookingDates}>{`${startDate} - ${endDate}, ${endYear}`}</span>
              </>
            ) : null}
            {showRecommendedProfiles ? (
              <>
                <span className={css.separator}>•</span>
                <InlineTextButton
                  className={css.viewProfessionalsButton}
                  onClick={onOpenProfessionalsModal}
                >
                  <FormattedMessage id="ListingPage.viewProfessionals" />
                </InlineTextButton>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
