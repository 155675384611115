import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionPurchaseOrder = props => {
  const { publicData } = props;
  const purchaseOrder = publicData?.purchaseOrder;

  if (!purchaseOrder) {
    return null;
  }
  return (
    <div className={css.sectionPurchaseOrder}>
      <h2 className={css.purchaseOrderTitle}>
        <FormattedMessage id="ListingPage.purchaseOrder" />
      </h2>
      {purchaseOrder}
    </div>
  );
};

export default SectionPurchaseOrder;
