import React from 'react';
import PropTypes from 'prop-types';

const TimeIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        d="M.857 12A11.142 11.142 0 1 0 12 .857a12.394 12.394 0 0 0-8.571 3.429"
        fill="none"
        stroke="#656464"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m4.286.857-.857 3.429 3.428.857M12 6v6.857l-4.457 2.229"
        fill="none"
        stroke="#656464"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

TimeIcon.defaultProps = { className: null };

const { string } = PropTypes;

TimeIcon.propTypes = { className: string };

export default TimeIcon;
