import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        style={{
          fill: 'none',
          stroke: '#656464',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        d="M.5 7 7 .5 13.5 7M2.5 8.5v5h9v-5"
        transform="scale(1.71429)"
      />
    </svg>
  );
};

HomeIcon.defaultProps = { className: null };

const { string } = PropTypes;

HomeIcon.propTypes = { className: string };

export default HomeIcon;
