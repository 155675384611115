import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';

import css from './ListingPage.module.css';

const SectionDocumentsMaybe = props => {
  const { documents } = props;

  if (!documents || documents?.length < 1) {
    return null;
  }

  return (
    <div className={css.sectionDocuments}>
      <h2 className={css.documentsTitle}>
        <FormattedMessage id="ListingPage.documentsTitle" values={{ count: documents?.length }} />
      </h2>
      <div className={css.documents}>
        {documents.map(document => {
          return (
            <ExternalLink key={document.id} className={css.document} href={document.link}>
              {document.name}
            </ExternalLink>
          );
        })}
      </div>
    </div>
  );
};

export default SectionDocumentsMaybe;
