import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';

import css from './ListingPage.module.css';

const SectionWorkOrderLink = props => {
  const { publicData } = props;
  const workOrderLink = publicData?.workOrderLink;

  if (!workOrderLink) {
    return null;
  }
  return (
    <div className={css.sectionWorkOrderLink}>
      <h2 className={css.workOrderLinkTitle}>
        <FormattedMessage id="ListingPage.workOrderLink" />
      </h2>
      <ExternalLink href={workOrderLink}>{workOrderLink}</ExternalLink>
    </div>
  );
};

export default SectionWorkOrderLink;
