import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { format as formatPhoneNumber } from '../../components/FieldPhoneNumberInput/fiFormatter';

import TimeIcon from './TimeIcon';
import LocationIcon from './LocationIcon';
import PhoneIcon from './PhoneIcon';
import HomeIcon from './HomeIcon';
import css from './ListingPage.module.css';

const OTHER_OPTION = 'other';

const SectionInformations = props => {
  const { author, isAuthorPro, publicData, timeframeOptions, occupationOptions } = props;

  const listingTimeframe = timeframeOptions.find(t => t.key === publicData?.timeframe)?.label;
  const listingOtherTimeframe =
    listingTimeframe?.toLowerCase() === OTHER_OPTION && publicData?.otherTimeframe;
  const listingLocation = publicData?.listingLocation;

  const listingOccupation = occupationOptions.find(t => t.key === publicData?.occupation)?.label;

  const phoneNumber = author.attributes.profile.publicData?.phoneNumber;
  const phoneNumberVisibility = publicData?.phoneNumber;
  const showPhoneNumber = phoneNumber && phoneNumberVisibility === 'public';

  if (!listingTimeframe && !listingLocation) {
    return null;
  }

  return (
    <div className={css.sectionInformations}>
      <h2 className={css.informationsTitle}>
        <FormattedMessage id="ListingPage.informationsTitle" />
      </h2>
      <ul className={css.informationsList}>
        {listingTimeframe ? (
          <li className={css.informationsListItem}>
            <TimeIcon className={css.informationsIcon} />
            {listingOtherTimeframe ? listingOtherTimeframe : listingTimeframe}
          </li>
        ) : null}
        {listingLocation ? (
          <li className={css.informationsListItem}>
            <LocationIcon className={css.informationsIcon} />
            <FormattedMessage
              id="ListingPage.informationsLocation"
              values={{ location: listingLocation }}
            />
          </li>
        ) : null}
        {listingOccupation ? (
          <li className={css.informationsListItem}>
            <HomeIcon className={css.informationsIcon} />
            {listingOccupation}
          </li>
        ) : null}
        {isAuthorPro && showPhoneNumber ? (
          <li className={css.informationsListItem}>
            <PhoneIcon className={css.informationsIcon} />
            <FormattedMessage
              id="ListingPage.informationsPhone"
              values={{
                link: (
                  <a href={`tel:${phoneNumber}`}>
                    {formatPhoneNumber(phoneNumber)?.replaceAll(' ', '-')}
                  </a>
                ),
              }}
            />
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default SectionInformations;
