import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        d="M12 23.143c6.154 0 11.143-4.989 11.143-11.143C23.143 5.846 18.154.857 12 .857 5.846.857.857 5.846.857 12c0 6.154 4.989 11.143 11.143 11.143Z"
        fill="none"
        stroke="#656464"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.857 18 3.429-10.286L6 11.143l4.286 2.571L12.857 18Z"
        fill="none"
        stroke="#656464"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LocationIcon.defaultProps = { className: null };

const { string } = PropTypes;

LocationIcon.propTypes = { className: string };

export default LocationIcon;
